const List = [{
		Nation: 'TH',
		AreaCode: '66',
		urlList: [{
				url: "https://c.lazada.co.th/t/c.bg27oH?intent=false&fallback=true&url=https%3A%2F%2Fwww.lazada.co.th%2Fshop%2Fsimplus-official-store",
				name: "Lazada",
				type: 1
			},
			{
				url: "https://shopee.co.th/simplus_officialshop?utm_campaign=s448235616_ss_th_othr_crmh5&utm_content=crm&utm_medium=seller&utm_source=brandcrm",
				name: "Shopee",
				type: 1
			},
			{
				url: "https://www.TikTok.com/@simplusappliance",
				name: "TikTok",
				type: 1
			},
			{
				url: "https://simplus.online/",
				name: "Offline",
				type: 2
			},
			{
				url: "https://simplus.online/",
				name: "Other",
				type: 2
			}
		],
		iphonverlist: '^(\\+?0?66\\-?)?\\d{10}$',
		iphonverlists: '!/^(\\+?0?66\\-?)?\\d{10}$/',
		phoneRegex: '^(\\+?0?66\\-?)?\\d{10}$',
	},
	{
		Nation: 'VN',
		AreaCode: '84',
		urlList: [{
				url: "https://www.lazada.vn/shop/simplus-vietnam",
				name: "Lazada",
				type: 1
			},
			{
				url: "https://shopee.vn/simplus_official",
				name: "Shopee",
				type: 1
			},
			{
				url: "https://www.TikTok.com/@simplus.vn",
				name: "TikTok",
				type: 1
			},
			{
				url: "https://simplus.online/",
				name: "Offline",
				type: 2
			},
			{
				url: "https://simplus.online/",
				name: "Other",
				type: 2
			}
		],
		iphonverlist: '^(0)\\d{9}$',
		iphonverlists: '!/^(0)/\\d{9}$/',
		phoneRegex: '^(\\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$'
	},

	{
		Nation: 'ID',
		AreaCode: '62',
		urlList: [{
				url: "https://www.lazada.co.id/shop/simplus/",
				name: "Lazada",
				type: 1
			},
			{
				url: "https://shopee.co.id/simplusindonesia",
				name: "Shopee",
				type: 1
			},
			{
				url: "https://www.TikTok.com/@simplus_id",
				name: "TikTok",
				type: 1
			},
			{
				url: "https://www.tokopedia.com/simplus",
				name: "Tokopedia",
				type: 1
			},
			{
				url: "https://simplus.online/",
				name: "Offline",
				type: 2
			},
			{
				url: "https://simplus.online/",
				name: "Other",
				type: 2
			}
		],
		iphonverlist: '^(08)\\d{8,11}$',
		iphonverlists: '!/^(08)\\d{8,11}$/',
		phoneRegex: '^((?:\\+62|62)|0)[2-9]{1}[0-9]+$'
	},
	{
		Nation: 'MY',
		AreaCode: '60',
		urlList: [{
				url: "https://www.lazada.com.my/shop/simplus-malaysia-official",
				name: "Lazada",
				type: 1
			},
			{
				url: "https://shopee.com.my/simplus_malaysia",
				name: "Shopee",
				type: 1
			},
			{
				url: "https://www.TikTok.com/@simplus.my",
				name: "TikTok",
				type: 1
			},
			{
				url: "https://simplus.online/",
				name: "Offline",
				type: 2
			},
			{
				url: "https://simplus.online/",
				name: "Other",
				type: 2
			}
		],
		iphonverlist: '^(1|0)\\d{9}$',
		iphonverlists: '!/^(1|0)\\d{9}$/',
		phoneRegex: '^(\\+?6?01){1}(([145]{1}(\\-|\\s)?\\d{7,8})|([236789]{1}(\\s|\\-)?\\d{7}))$'
	},
	{
		Nation: 'SG',
		AreaCode: '65',
		urlList: [{
				url: "https://www.lazada.sg/shop/simplus-official-store",
				name: "Lazada",
				type: 1
			},
			{
				url: "https://shopee.sg/simplus_official_store",
				name: "Shopee",
				type: 1
			},
			{
				url: "https://www.TikTok.com/@simplus_singapore",
				name: "TikTok",
				type: 1
			},
			{
				url: "https://simplus.online/",
				name: "Offline",
				type: 2
			},
			{
				url: "https://simplus.online/",
				name: "Other",
				type: 2
			}
		],
		iphonverlist: '^(8|9)\\d{7}$',
		iphonverlists: '!/^(8|9)\\d{7}$/',
		phoneRegex: '^(\\+?0?65\\-?)?\\d{10}$'
	},
	{
		Nation: 'PH',
		AreaCode: '63',
		urlList: [{
				url: "https://www.lazada.com.ph/shop/simplus/",
				name: "Lazada",
				type: 1
			},
			{
				url: "https://shopee.ph/simplusphilippines",
				name: "Shopee",
				type: 1
			},
			{
				url: "https://www.TikTok.com/@simplus_ph",
				name: "TikTok",
				type: 1
			},
			{
				url: "https://simplus.online/",
				name: "Offline",
				type: 2
			},
			{
				url: "https://simplus.online/",
				name: "Other",
				type: 2
			}
		],
		iphonverlist: '^(08|09)\\d{9}$',
		iphonverlists: '!/^(08|09)\\d{9}$/',
		phoneRegex: '^(\\+?0?63\\-?)?\\d{10}$'
	},
]

export {
	List
};
export const langData = [{
		Nation: 'TH',
		newLangInfo: {
			//注册前弹窗
			registerModel: {
				title: 'คุณเคยลงทะเบียนเป็นสมาชิก simplus มาก่อนหรือไม่?',
				button1: 'ไม่เคย ลงทะเบียนตอนนี้',
				button2: 'ใช่ เข้าสู่ระบบ ',
				button3: 'เข้าสู่ระบบ',
				tips: 'โปรดเลือก',
			},
			//注册页
			registerPage: {
				name: 'ชื่อ',
				email: 'อีเมล',
				phone: 'หมายเลขโทรศัพท์',
				gender: 'เพศ',
				birth: 'วันเกิด',
				fill: 'กรุณาเลือก',
				select: 'กรุณาเลือก',
				last: 'ก่อนหน้า',
				next: 'ขั้นตอนต่อไป',
				submit: 'ส่ง',
				signup: 'ลงทะเบียน',
				mandatory: 'จำเป็นต้องกรอก',
			},
			//注册页2
			questPage: {
				title: 'คุณสั่งซื้อสินค้า Simplus บนแพลตฟอร์มใด?',
				orderid: 'รหัสคำสั่งซื้อ',
				upload: 'อัปโหลดใบเสร็จ',
				offline: 'ช่องทางออฟไลน์',
				facebook: 'ID Line ของคุณ',
				other: 'อื่นๆ',
			},
			//登录弹窗
			loginModel: {
				email: 'อีเมล',
				phone: 'หมายเลขโทรศัพท์',
				submit: 'เข้าสู่ระบบ',
				tips: "ไม่มีบัญชีหรือใส่ไม่ถูกต้อง",
				emailTips: 'รูปแบบอีเมลผิดพลาด',
				phoneTips: 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
				phoneTips2:"หากเข้าสู่ระบบด้วยหมายเลขโทรศัพท์ล้มเหลว, ให้เลือกเข้าสู่ระบบด้วยอีเมล"
			},
			//个人中心页
			infoPage: {
				pendding: 'รอการตรวจสอบ',
				pendTitle: 'โปรดรอผลการตรวจสอบ, ผลลัพธ์จะถูกส่งไปทางอีเมลของคุณ, คุณยังสามารถเข้าสู่ระบบอีกครั้งเพื่อดูความคืบหน้าของการตรวจสอบ',
				fail: 'ตรวจสอบไม่ผ่าน',
				failTitle: "ขออภัย, การลงทะเบียนของคุณล้มเหลว ,โปรดติดต่อทีมแอดมินเพื่อขอความช่วยเหลือ",
				userId: 'ไอดี',
				regDate: 'วันที่ลงทะเบียน',
				shareText: 'แชร์ความคิดเห็น, รับคูปองส่วนลดสำหรับสมาชิก',
				$20: 'คูปองส่วนลด 20บาท',
				$50: 'คูปองส่วนลด 50บาท',
				unit: 'ไม่มีเกณฑ์',
				collect: 'รับ',
				only: 'เฉพาะสมาชิก Simplus เท่านั้น',
				activity: 'กิจกรรมพิเศษสำหรับสมาชิก'
			},
			//引导页
			guidePage: {
				register: 'ลงทะเบียน',
				signin: 'เข้าสู่ระบบ',
				title: 'การสมัครสมาชิก Simplus สำหรับลูกค้าที่สั่งซื้อผลิตภัณฑ์ Simplus!',
				submit: "รีบช้อปเลย",
			},
			//表单页
			formPage: {
				title20: 'ขอบคุณสำหรับการสั่งซื้อสินค้าขายดีของ Simplus - Air Fryer!โปรดกรอกข้อมูลการสั่งซื้อเพื่อรับคูปองส่วนลด 20% , ลดสูงสุดถึง 500บาท! Simplus ตั้งตารอการช้อปของคุณในครั้งต่อไปอยู่นะ!',
				title50: 'ขอบคุณสำหรับการสั่งซื้อสินค้าขายดีของ Simplus - Air Fryer!โปรดกรอกข้อมูลการสั่งซื้อเพื่อรับคูปองส่วนลด 50% , ลดสูงสุดถึง 500บาท! Simplus ตั้งตารอการช้อปของคุณในครั้งต่อไปอยู่นะ!',
				succTitle: 'Submission succeeds!',
				succText20: 'แอดมินจะตรวจสอบข้อมูลของคุณภายใน 3 วันทำการ , โปรดอดใจรอ!หลังจากผ่านการตรวจสอบแล้ว คูปองส่วนลด 20% จะถูกส่งถึงคุณในช่องทางแชท Line ตรวจสอบให้ดีนะ!',
				succText50: 'แอดมินจะตรวจสอบข้อมูลของคุณภายใน 3 วันทำการ , โปรดอดใจรอ!หลังจากผ่านการตรวจสอบแล้ว คูปองส่วนลด 50% จะถูกส่งถึงคุณในช่องทางแชท Line ตรวจสอบให้ดีนะ!'
			},
			tipsInfo: {
				email: 'รูปแบบอีเมลผิดพลาด',
				birth: 'วันเกิดไม่ถูกต้อง , กรุณาตรวจสอบและลองใหม่',
				gender: 'การป้อนข้อมูลผิดพลาด',
				exist: 'ผู้ใช้ได้ลงทะเบียนแล้ว',
				order_error: 'หมายเลขคำสั่งซื้อไม่ถูกต้อง ,กรุณาตรวจสอบและลองใหม่',
				phone: 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
				order_used: 'หมายเลขคำสั่งซื้อนี้ได้เข้าร่วมโปรโมชั่นแล้ว',
				http: 'โปรดป้อน URL ที่ถูกต้อง (ขึ้นต้นด้วย http)',
			}
		},
		index: {
			rtext: 'สมาชิกใหม่',
			rbtn: 'ลงทะเบียน ',
			stext: 'เป็นสมาชิกอยู่แล้ว',
			sbtn: 'เข้าสู่ระบบ',
			ctext: 'นานาชาติ',
		},
		user: {
			text: 'สอบถามข้อมูลสมาชิก',
			ptitle: 'หมายเลขโทรศัพท์',
			ptext: 'Phone No.',
			or: 'หรือ',
			etitle: 'ที่อยู่อีเมล',
			etext: 'Email',
			ctext: 'นานาชาติ',
			sbtn: 'เข้าสู่ระบบ',
			rdtext: 'วันที่ลงทะเบียน'
		},
		registration: {
			ctext: 'นานาชาติ',
			PTip: 'กรุณากรอกหมายเลขโทรศัพท์มือถือที่ไม่มีรหัสประเทศ',
			tiptext: 'ขอบคุณที่เลือก Simplus! เข้าร่วมเป็นสมาชิก Simplus , รับประกันการเปลี่ยนสินค้า 1 ปี และ คูปองสุดพิเศษ!  กรอกแบบฟอร์ม , ลงทะเบียนเลย!',
			Privacytip: " การลงทะเบียนเป็นสมาชิกหมายความว่าคุณยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดการใช้งานของ Simplus",
			tipredtext: 'จำเป็นต้อง.',
			mobiletip: '* กรุณากรอกหมายเลขโทรศัพท์มือถือที่ไม่มีรหัสประเทศ',
			Emailautotip: "คูปองของคุณจะถูกส่งไปยังอีเมลของคุณเร็ว ๆ นี้",
			Nextstep: 'ถัดไป',
			Previous: 'ก่อนหน้า',
			userCenter: 'ศูนย์ผู้ใช้',
			prerequisites: {
				title: 'คุณเคยซื้อผลิตภัณฑ์ Simplus มาก่อนมั้ย?',
				list: [{
					value: 'yes',
					label: 'ใช่แล้ว,  อยากจะแบ่งปันรีวิวและรับคูปองส่วนลด 50% (ใช้ได้ในการสั่งซื้อครั้งต่อไป)',
				}, {
					value: 'no',
					label: 'ไม่, ฉันต้องการเป็นสมาชิกของ Simplus'
				}],
			},
			member: {
				Title: 'โพสต์รีวิวรับเวาเชอร์ 30 บาท!!!',
				onetitle: "แชร์ภาพหน้าจอรีวิวระดับ 5 ดาวของคุณ (โปรดอย่าใส่การ์ดขอบคุณไว้ในภาพนี้)",
				onetip: "อัพโหลดไฟล์",
				twotitle: 'แพลตฟอร์มที่คุณซื้อสินค้า',
				twolist: ['Lazada', 'Shopee', 'TikTok', 'อื่นๆ'],
				Introductory: "ขอบคุณที่เลือกผลิตภัณฑ์ของ Simplus, โปรดกรอกข้อมูลสินค้าในออเดอร์ของคุณเพื่อรับคูปอง 50% ,สำหรับการสั่งซื้อสินค้า Simplus ในครั้งต่อไปของคุณ:",
				chatID: "LINE ID",
				chatTips: "โปรดกรอกข้อมูล",
				OrderID: " หมายเลขคำสั่งซื้อ",
				OrderTips: "โปรดกรอกข้อมูล",
				Platform: "แพลตฟอร์มที่แสดงความคิดเห็นรีวิว(ตัวเลือกเดียว)",
				EvaluationPlatform: "แพลตฟอร์มที่แสดงความคิดเห็นรีวิว(ตัวเลือกเดียว)",
				EvaluationURl: "(optional)ภาพแคปหน้าจอที่แสดงความคิดเห็น",
				EvaluationURlTips: "โปรดอัพโหลดรูปภาพ",
				EvaluationString: "ลิงก์ความคิดเห็น",
				EvaluationStringTips: "โปรดกรอกข้อมูล",
				Nation: null,
				btntitle: "เสร็จสิ้นการสมัคร",
				FormConclusion: "ยินดีด้วย! คุณลงทะเบียนสำเร็จแล้ว!  รีบมาช้อปและเอนจอยกับสิทธิประโยชน์การรับประกัน 1 ปีได้เลย!",
			},
			sliginform: {
				Title: 'ลงชื่อสมาชิก Simplus ของเรา',
				e_title: 'อีเมล',
				p_title: 'เบอร์โทรศัพท์',
				p_title_red: ' *(mobile number)',
				p_title_tip: ' เบอร์โทรศัพท์ไม่ถูกต้อง',
				e_title_tip: ' อีเมลไม่ถูกต้อง',
				n_title: 'ชื่อ',
				d_title: 'วันเกิด',
				g_title: 'เพศ',
				g_list: [{
						value: 'Male',
						label: 'ชาย',
					},
					{
						value: 'Female',
						label: 'หญิง',
					},
					{
						value: 'Not_Specified',
						label: 'ความลับ',
					},
					{
						value: 'Other',
						label: 'อื่นๆ',
					}
				],
				Where_title: 'คุณเจอเราได้อย่างไร',
				Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok', 'โฆษณาลิฟต์'],
				Where_other: 'other (Please specify)',
				lastmeber: 'การลงทะเบียนเป็นสมาชิกหมายความว่าคุณยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดการใช้งานของ Simplus',
				lasttext: "การสร้างบัญชีหมายความว่าคุณยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดการใช้งานของ Simplus",
				downbtntext: 'ก่อนหน้า',
				upbtntexts: 'ก่อนหน้า',
				upbtntext: 'ก่อนหน้า',
				submitext: 'สิ้นสุดการลงทะเบียน',
			}
		},
	},
	{
		Nation: 'VN',
		newLangInfo: {
			//注册前弹窗
			registerModel: {
				title: 'Bạn đã đăng ký trở thành thành viên của Simplus chưa?',
				button1: 'Chưa, sẽ đăng ký bây giờ',
				button2: 'Vâng, đã đăng ký ',
				button3: 'Đăng nhập',
				tips: 'Vui lòng chọn',
			},
			//注册页
			registerPage: {
				name: 'Họ tên',
				email: 'Email',
				phone: 'Số điện thoại',
				gender: 'Giới tính',
				birth: 'Sinh nhật',
				fill: 'Vui lòng điền',
				select: 'Vui lòng chọn',
				last: 'Trở về',
				next: 'Tiếp tục',
				submit: 'Gửi',
				signup: 'Đăng ký',
				mandatory: 'Bắt buộc điền',
			},
			//注册页2
			questPage: {
				title: 'Bạn mua sản phẩm Simplus từ đâu?',
				orderid: 'Số đơn hàng',
				upload: 'Tải lên chứng minh mua hàng',
				offline: 'Cửa hàng offline',
				facebook: 'Link trang chủ Facebook của bạn',
				other: 'Khác',
			},
			//登录弹窗
			loginModel: {
				email: 'Email',
				phone: 'Số điện thoại',
				submit: 'Đăng nhập',
				tips: "Tài khoản không tồn tại hoặc nhập sai",
				emailTips: 'Email không hợp lệ',
				phoneTips: 'số điện thoại không hợp lệ ',
				phoneTips2:"Nếu đăng nhập số điện thoại thất bại, Vui lòng đăng nhập bằng Email"
			},
			//个人中心页
			infoPage: {
				pendding: 'Đang duyệt',
				pendTitle: 'Vui lòng chờ duyệt! Kết quả sẽ được thông báo qua Email. Bạn cũng có thể đăng nhập để kiểm tra trạng thái duyệt',
				fail: 'Thẩm duyệt không thông qua',
				failTitle: "Rất tiếc, Đăng ký thành viên của bạn không thông qua, vui lòng liên hệ CSKH",
				userId: 'Mã số',
				regDate: 'Ngày đăng ký',
				shareText: 'Thêm thông tin Review, nhận ngay ưu đãi dành riêng cho thành viên',
				$20: 'Voucher ฿20',
				$50: 'Mã giảm giá 50%',
				unit: 'không yêu cầu giá trị đơn hàng',
				collect: 'Nhận ngay',
				only: 'Hoạt động chỉ dành cho khách hàng thành viên Simplus',
				activity: 'Hoạt động dành riêng cho thành viên'
			},
			//引导页
			guidePage: {
				register: 'Trở thành thành viên',
				signin: 'Đã là thành viên',
				title: 'Chỉ khách hàng đã mua sản phẩm Simplus mới có thể đăng ký thành viên',
				submit: "Mua ngay",
			},
			//表单页
			formPage: {
				title20: 'Cảm ơn bạn đã mua sản phẩm Hot nhà Simplus - Nồi chiên không dầu! Vui lòng điền thông tin đơn hàng, Nhận ưu đãi giảm giá 20%, Lên tới 500! Simplus mong bạn sẽ tiếp tục ủng hộ!',
				title50: 'Cảm ơn bạn đã mua sản phẩm Hot nhà Simplus - Nồi chiên không dầu! Vui lòng điền thông tin đơn hàng, Nhận ưu đãi giảm giá 50%, Lên tới 350,000! Simplus mong bạn sẽ tiếp tục ủng hộ!',
				succTitle: 'Submission succeeds!',
				succText20: 'Chúng tôi sẽ duyệt thông tin của bạn trong 3 ngày làm việc, Vui lòng chờ!  Khi duyệt thành công, Voucher 20% sẽ được gửi qua tin nhắn Facebook, Xin vui lòng kiểm tra!',
				succText50: 'Chúng tôi sẽ duyệt thông tin của bạn trong 3 ngày làm việc, Vui lòng chờ!  Khi duyệt thành công, Voucher 50% sẽ được gửi qua tin nhắn Facebook, Xin vui lòng kiểm tra!'
			},
			tipsInfo: {
				email: 'Email không hợp lệ',
				birth: 'Ngày sinh không hợp lệ, Vui lòng nhập lại',
				gender: 'Không chính xác',
				exist: 'Người dùng đã tồn tại',
				order_error: 'Đơn hàng không hợp lệ, vui lòng nhập lại',
				phone: 'số điện thoại không hợp lệ',
				order_used: 'Đơn đặt hàng này đã tham gia hạot động khuyến mãi',
				http: 'Vui lòng nhập chính xác địa chỉ trang Web(http)',
			}
		},
		index: {
			rtext: 'thành viên mới',
			rbtn: 'Đăng ký',
			stext: 'Đã là thành viên hay chưa.',
			sbtn: 'Đăng nhập.',
			ctext: 'quốc tế',
		},
		user: {
			text: 'Yêu cầu thông tin thành viên',
			ptitle: 'số điện thoại',
			ptext: 'Phone No.',
			or: 'hoặc',
			etitle: 'địa chỉ email',
			etext: 'Email',
			ctext: 'Quốc gia',
			sbtn: 'Đăng nhập',
			rdtext: 'Ngày đăng ký'
		},
		registration: {
			ctext: 'Quốc gia',
			PTip: 'Hãy nhập số điện thoại mà không có mã quốc gia.',
			tiptext: 'Cảm ơn bạn đã lựa chọn Simplus! Trở thành thành viên Simplus và nhận bảo hành thay thế 1 năm và các phiếu mua hàng độc quyền!',
			tipredtext: 'Bắt buộc.',
			Privacytip: "Đăng ký làm thành viên có nghĩa là bạn chấp nhận Chính sách quyền riêng tư và Điều khoản sử dụng của Simplus",
			mobiletip: 'Vui lòng nhập số điện thoại không có mã quốc gia.',
			Emailautotip: "Tấm vé của bạn sẽ sớm được gửi tới email của bạn",
			Nextstep: 'bước tiếp theo',
			Previous: 'Trở về trang trước đó',
			userCenter: 'Trung tâm người dùng',
			prerequisites: {
				title: 'Bạn đã từng mua sản phẩm Simplus chưa?',
				list: [{
					value: 'yes',
					label: 'tôi đã từng mua ạ , tôi muốn chia sẻ đánh giá của mình và nhận phiếu giảm giá 50% (có giá trị cho đơn hàng tiếp theo)',
				}, {
					value: 'no',
					label: 'Chưa, tôi muốn trở thành thành viên simplus'
				}],
			},
			member: {
				Title: 'ĐĂNG PHẦN ĐÁNH GIÁ ĐỂ NHẬN PHIẾU GIẢM GIÁ 10%',
				onetitle: "Chia sẻ ảnh chụp màn hình phần đánh giá 5 sao của bạn ~ (Vui lòng không đăng phiếu tri ân này kèm với ảnh này)",
				onetip: "tải tệp lên",
				twotitle: 'Nền tảng bạn đã mua sắm',
				twolist: ['Lazada', 'Shopee', 'TikTok', 'Khác'],
				Introductory: "Cảm ơn bạn đã chọn sản phẩm của Simplus, vui lòng điền thông tin danh sách sản phẩm của bạn để nhận phiếu giảm giá 50% cho đơn hàng Simplus tiếp theo :",
				chatID: "ID Facebook của bạn",
				chatTips: "Vui lòng điền",
				OrderID: " Mã đơn hàng",
				OrderTips: "Vui lòng điền",
				Platform: "Nền tảng đặt hàng (một lựa chọn)",
				EvaluationPlatform: "Nền tảng bình luận (một lựa chọn) ",
				EvaluationURl: "Ảnh chụp màn hình nhận xét (tùy chọn)",
				EvaluationURlTips: "vui lòng tải lên",
				EvaluationString: "Liên kết bình luận",
				EvaluationStringTips: "Vui lòng điền",
				Nation: null,
				btntitle: "Hoàn thành đơn đăng ký",
				FormConclusion: "Xin chúc mừng! Bạn đã đăng ký thành công! Hãy đến cửa hàng và tận hưởng lợi ích bảo hành 1 năm của bạn!",
			},
			sliginform: {
				Title: 'Đăng ký Thành viên Simplus của chúng tôi',
				e_title: 'Địa chỉ email(Vui lòng điền chính xác địa chỉ Email)',
				p_title: 'Số điện thoại',
				p_title_red: ' *(mobile number)',
				p_title_tip: 'Số điện thoại không hợp lệ',
				e_title_tip: 'Địa chỉ email không hợp lệ',
				n_title: 'Họ tên',
				d_title: 'Ngày sinh',
				g_title: 'Giới tính',
				g_list: [{
						value: 'Male',
						label: 'Nam',
					},
					{
						value: 'Female',
						label: 'Nữ',
					},
					{
						value: 'Not_Specified',
						label: 'Bí mật',
					},
					{
						value: 'Other',
						label: 'Khác',
					}
				],
				Where_title: 'Bạn đã tìm thấy chúng tôi qua kênh nào?',
				Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok'],
				Where_other: 'Khác (vui lòng ghi rõ)',
				lastmeber: 'Đăng ký làm thành viên có nghĩa là bạn chấp nhận Chính sách quyền riêng tư và Điều khoản sử dụng của Simplus',
				lasttext: "Đăng ký thành viên nghĩa là bạn chấp nhận Chính sách Quyền riêng tư và Điều khoản Sử dụng của Simplus",
				downbtntext: 'bước tiếp theo',
				upbtntexts: 'Bước trước ',
				upbtntext: 'Bước trước',
				submitext: 'Hoàn thành đơn đăng ký',
			}
		}
	},
	{
		Nation: 'ID',
		newLangInfo: {
			//注册前弹窗
			registerModel: {
				title: 'Apakah Anda sudah pernah mendaftar sebagai anggota Simplus sebelumnya?',
				button1: 'Tidak, daftar sekarang',
				button2: 'Ya, masuk',
				button3: 'Masuk',
				tips: 'Silahkan centang',
			},
			//注册页
			registerPage: {
				name: 'Nama',
				email: 'Email',
				phone: 'Nomor Telepon',
				gender: 'Jenis Kelamin',
				birth: 'Ulang Tahun',
				fill: 'Tolong isi',
				select: 'Silahkan pilih',
				last: 'Kembali',
				next: 'Selanjutnya',
				submit: 'Kirim',
				signup: 'Daftar',
				mandatory: 'Wajib diisi',
			},
			//注册页2
			questPage: {
				title: 'Di mana Anda membeli produk Simplus?',
				orderid: 'Nomor Orderan',
				upload: 'Unggah tanda terima',
				offline: 'Pasar Offline',
				facebook: 'Tautan Halaman Facebook Anda',
				other: 'Lainnya',
			},
			//登录弹窗
			loginModel: {
				email: 'Email',
				phone: 'Nomor Telepon',
				submit: 'Masuk',
				tips: "Akun tidak ada atau salah",
				emailTips: 'Format email salah',
				phoneTips: 'Format nomor telepon salah',
				phoneTips2:"Jika login nomor ponsel gagal, Anda dapat memilih email untuk login"
			},
			//个人中心页
			infoPage: {
				pendding: 'Menunggu peninjauan',
				pendTitle: 'Hasilnya akan dikirim melalui email Anda. Anda juga dapat masuk untuk melihat prosesnya. Terima kasih atas kesabaran Anda!',
				fail: 'Registrasi Gagal',
				failTitle: "Kami mohon maaf karena pendaftaran Anda gagal, silakan hubungi tim layanan pelanggan kami untuk mendapatkan bantuan.",
				userId: 'Nomor',
				regDate: 'Tanggal registrasi',
				shareText: 'Bagikan ulasan Anda dan dapatkan voucher eksklusif anggota!',
				$20: 'Voucher ฿20',
				$50: 'Voucher Diskon 50%',
				unit: 'Tanpa Batas',
				collect: 'Mengumpulkan',
				only: 'Hanya untuk anggota Simplus',
				activity: 'Aktivitas Eksklusif Anggota'
			},
			//引导页
			guidePage: {
				register: 'Daftar sekarang',
				signin: 'Masuk',
				title: 'Pendaftaran keanggotaan Simplus adalah untuk pelanggan yang telah membeli produk Simplus!',
				submit: "Belanja Sekarang",
			},
			//表单页
			formPage: {
				title20: 'Terima kasih telah membeli produk terlaris Simplus, Air Fryer! Silakan isi informasi pesanan Anda untuk mendapatkan voucher diskon 20%, diskon hingga 500! Simplus menantikan belanja Anda berikutnya!',
				title50: 'Terima kasih telah membeli produk terlaris Simplus, Air Fryer! Silakan isi informasi pesanan Anda untuk mendapatkan voucher diskon 50%, diskon hingga xxx! Simplus menantikan belanja Anda berikutnya!',
				succTitle: 'Submission succeeds!',
				succText20: 'Tim Customer Service kami akan memverifikasi informasi Anda dalam waktu 3 hari kerja. Terima kasih atas kesabaran Anda! Setelah diverifikasi, voucher diskon 20% akan dikirimkan kepada Anda melalui kotak obrolan Facebook, silakan periksa!',
				succText50: 'Tim Customer Service kami akan memverifikasi informasi Anda dalam waktu 3 hari kerja. Terima kasih atas kesabaran Anda! Setelah diverifikasi, voucher diskon 50% akan dikirimkan kepada Anda melalui kotak obrolan Facebook, silakan periksa!'
			},
			tipsInfo: {
				email: 'Format email salah',
				birth: 'Format ulang tahun tidak valid, harap masukkan kembali',
				gender: 'Kesalahan input',
				exist: 'Pengguna telah terdaftar',
				order_error: 'Nomor pesanan tidak valid, harap masukkan kembali',
				phone: 'Format nomor telepon salah',
				order_used: 'Akun tidak ada atau salah',
				http: 'Harap masukkan format URL yang benar (dimulai dengan http)',
			}
		},
		index: {
			rtext: 'Anggota baru',
			rbtn: 'Daftar',
			stext: 'Sudah menjadi anggota',
			sbtn: 'Masuk',
			ctext: 'Negara',
		},
		user: {
			text: 'isi informasi anggota',
			ptitle: 'Nomor HP',
			ptext: 'No. HP',
			or: 'atau',
			etitle: 'Alamat E-mail',
			etext: 'Email',
			ctext: 'Negara',
			sbtn: 'Masuk',
			rdtext: 'tanggal registrasi'
		},
		registration: {
			ctext: 'Negara',
			PTip: 'Silakan masukkan nomor ponsel tanpa kode negara',
			tiptext: 'Terima kasih anda telah memilih Simplus!Bergabunglah menjadi anggota Simplus, dapatkan garansi penggantian 1 tahun dan voucher eksklusif!  Isi formulir, selesaikan pendaftaran!',
			Privacytip: "Mendaftar sebagai anggota berarti anda menyetujui Kebijakan Privasi dan Ketentuan Penggunaan Simplus",
			tipredtext: 'Harus diisi.',
			mobiletip: '* Silakan masukkan nomor ponsel tanpa kode negara.',
			Emailautotip: "  Kupon Anda akan segera dikirim ke email Anda.",
			Nextstep: 'lanjut',
			Previous: 'Langkah sebelumnya',
			userCenter: 'user Center',
			prerequisites: {
				title: 'Apakah sebelumnya ada pernah membeli produk Simplus?',
				list: [{
					value: 'yes',
					label: 'Iya. Saya ingin membagikan ulasan saya, dan mendapatkan voucher eksklusif potongan 50% (Berlaku pembelian berikutnya)',
				}, {
					value: 'no',
					label: 'Tidak. Saya ingin menjadi anggota Simplus'
				}],
			},
			member: {
				Title: 'POST REVIEW UNTUK MENDAPATKAN DISKON 10% VOUCHER',
				onetitle: "Bagikan screenshot ulasan bintang 5 Anda~ (Jangan taruh kartu ucapan terima kasih di dalam foto ini)",
				onetip: "unggah berkas",
				twotitle: 'Platform tempat Anda membeli',
				twolist: ['Lazada', 'Shopee', 'TikTok', 'lainnnya'],
				Introductory: "Terima kasih anda telah memilih produk SIMPLUS. Posting pengalaman menggunakan produk SIMPLUS di Media Sosial anda (Facebook/Instagram/TikTok) akan mendapatkan voucher diskon 50%off untuk pemesanan SIMPLUS berikutnya. Isi form, segera ikuti programnya",
				chatID: "Akun Facebook",
				chatTips: "Silahkan isi",
				OrderID: "Nomor Pesanan",
				OrderTips: "Silahkan isi",
				Platform: "Platform pemesanan (pilih satu) ",
				EvaluationPlatform: "Media sosial tempat menggirimkan postingan ",
				EvaluationURl: "Capture postingan (opsional)",
				EvaluationURlTips: "Silahkan unggah",
				EvaluationString: "Link postingan ",
				EvaluationStringTips: "Silahkan isi",
				Nation: null,
				btntitle: "aplikasi lengkap",
				FormConclusion: " Selamat! Anda telah berhasil mendaftar! Ayo berbelanja, nikmati manfaat garansi 1 tahun!",
			},
			sliginform: {
				Title: 'Daftar keanggotaan Simplus kami',
				e_title: 'Alamat E-mail',
				p_title: 'Nomor HP',
				p_title_red: '',
				p_title_tip: 'Nomor telepon tidak valid',
				e_title_tip: 'Alamat email tidak valid',
				n_title: 'Nama',
				d_title: 'Tanggal lahir',
				g_title: 'Jenis kelamin ',
				g_list: [{
						value: 'Male',
						label: 'Pria',
					},
					{
						value: 'Female',
						label: 'Wanita',
					},
					{
						value: 'Not_Specified',
						label: 'Memilih untuk tidak mengatakan',
					},
					{
						value: 'Other',
						label: 'Lainnya',
					}
				],
				Where_title: 'Dari mana Anda menemukan kami?',
				Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'TikTok', 'iklan lift', 'Yang lain'],
				Where_other: 'lainnnya (sebutkan)',
				lastmeber: ' Mendaftar sebagai anggota berarti anda menyetujui Kebijakan Privasi dan Ketentuan Penggunaan Simplus',
				lasttext: "Mendaftar sebagai anggota berarti Anda menerima kebijakan privasi dan syarat penggunaan Simplus",
				downbtntext: 'lanjut',
				upbtntexts: 'kembali',
				upbtntext: 'kembali',
				submitext: 'Selesai',
			}
		}
	},
	{
		Nation: 'SG',
		newLangInfo: {
			//注册前弹窗
			registerModel: {
				title: 'Have you registered as Simplus member before?',
				button1: 'No, register now',
				button2: "Yes, log in",
				button3: 'Log in',
				tips: 'Please tick'
			},
			//注册页
			registerPage: {
				name: 'Name',
				email: 'Email',
				phone: 'Phone Number',
				gender: 'Gender',
				birth: 'Birthday',
				fill: 'Please fill in',
				select: 'Please select',
				last: 'Back',
				next: 'Next',
				submit: 'Submit',
				signup: 'Sign up',
				mandatory: 'Mandatory',
			},
			//注册页2
			questPage: {
				title: 'Where did you purchase Simplus products?',
				orderid: 'Order ID',
				upload: 'Upload receipt',
				offline: 'Offline channels',
				facebook: 'Your Facebook page link',
				other: 'Other',
			},
			//登录弹窗
			loginModel: {
				email: 'Email',
				phone: 'Phone Number',
				submit: 'Log in',
				tips: "Account doesn't exist or wrong entered",
				emailTips: 'Email format error',
				phoneTips: 'Phone number format error',
				phoneTips2:"You can sign in by email if you fail to sign in by mobile phone number"
			},
			//个人中心页
			infoPage: {
				pendding: 'Pending on Review',
				pendTitle: 'The result will be sent via your email. You can also sign in to view the process. Thanks for your patience!',
				fail: 'Registration Failed',
				failTitle: "We're sorry that your registration has failed, please contact our customer service team for help.",
				userId: 'ID',
				regDate: 'Registered Date',
				shareText: 'Share your review and get member exclusive voucher!',
				$20: '฿20 Voucher',
				$50: '50% off Voucher',
				unit: 'No Limit',
				collect: 'Collect',
				only: 'Only for Simplus Member',
				activity: 'Member Exclusive Activity'
			},
			//引导页
			guidePage: {
				register: 'Register now',
				signin: 'Log in',
				title: 'Simplus membership registration is for customers who have purchased Simplus products!',
				submit: "Shop Now",
			},
			//表单页
			formPage: {
				title20: 'Thank you for purchasing Simplus best-selling product, Air Fryer! Please fill in your order information to get your 20% off voucher, up to 500 off! Simplus looks forward to your next shopping!',
				title50: 'Thank you for purchasing Simplus best-selling product, Air Fryer! Please fill in your order information to get your 50% off voucher, up to S$25 off! Simplus looks forward to your next shopping!',
				succTitle: 'Submission succeeds!',
				succText20: 'Our customer services team will verify your information within 3 working days. Thanks for your patience! Once verified. the 20% off voucher will be sent to you via Facebook chat box, please kindly check it!',
				succText50: 'Our customer services team will verify your information within 3 working days. Thanks for your patience! Once verified, the 50% off voucher will be sent to you via Facebook Messenger, please kindly check it!'
			},
			tipsInfo: {
				email: 'Email format error',
				birth: 'Invalid date of birth. Please re-enter.',
				gender: 'Input Error',
				exist: 'User has registered',
				order_error: 'Invalid order, please re-enter',
				phone: 'Phone number format error',
				order_used: 'This order has been used for the activity',
				http: 'Please enter the correct page link (starting with http).',
			}
		},
		index: {
			rtext: 'New Registration',
			rbtn: 'Join Now',
			stext: 'Already as Member',
			sbtn: 'Sgin in',
			ctext: 'Country',
		},
		user: {
			text: 'Query member information',
			ptitle: 'Phone Number',
			ptext: 'Phone No.',
			or: 'or',
			etitle: 'E-mail Address',
			etext: 'Email',
			ctext: 'Country',
			sbtn: 'Sgin in',
			rdtext: 'Registration date'
		},
		registration: {
			ctext: 'Country',
			PTip: 'Please enter mobile number without country code',
			tiptext: 'Thank you for choosing Simplus!  Join Simplus membership to get 1 year replacement warranty and exclusive voucher!  Fill in the form to complete the registration!',
			Privacytip: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
			tipredtext: 'Mandatory Field.',
			mobiletip: '* Please enter mobile number without country code.',
			Emailautotip: "Your coupon will be sent to your email shortly.",
			Nextstep: 'Next step',
			Previous: 'Previous',
			userCenter: 'user Center',
			prerequisites: {
				title: 'Have you shopped with Simplus before?',
				list: [{
					value: 'yes',
					label: 'Yes, I want to share my reviews and get 50%off voucher for my next order',
				}, {
					value: 'no',
					label: 'No, I want to become a Simplus member.'
				}],
			},
			member: {
				Title: 'POST REVIEW TO GET 10% OFF VOUCHER',
				onetitle: "Share your screenshot of the 5 stars review~ (Please don't put thank you card together within this photo)",
				onetip: "upload files",
				twotitle: 'Platform that you purchased from ',
				twolist: ['Lazada', 'Shopee', 'TikTok', 'Others'],
				Introductory: "Thank you for shopping with Simplus , please fill in your product review information to receive 50% off voucher on your next Simplus order",
				chatID: "Your Facebook account",
				chatTips: "Please fill in",
				OrderID: "Order ID",
				OrderTips: "Please fill in",
				Platform: " Your order platform",
				EvaluationPlatform: "Social platform where you leave reviews ",
				EvaluationURl: " Screenshot of your review page(optional)",
				EvaluationURlTips: "Please upload",
				EvaluationString: "Link to your review page",
				EvaluationStringTips: "Please fill in",
				Nation: null,
				btntitle: "Complete application",
				FormConclusion: "Congratulations! Your resgistration is successful! Come to shop and enjoy your 1 year warranty!",
			},
			sliginform: {
				Title: 'Sign up our Simplus Membership',
				e_title: 'E-mail Address',
				p_title: 'Phone Number',
				p_title_red: ' *(mobile number)',
				p_title_tip: 'Error entering mobile number',
				e_title_tip: 'Email format error',
				n_title: 'Name',
				d_title: 'Date of Birth',
				g_title: 'Gender',
				g_list: [{
						value: 'Male',
						label: 'Male',
					},
					{
						value: 'Female',
						label: 'Female',
					},
					{
						value: 'Not_Specified',
						label: 'Prefer not to say',
					},
					{
						value: 'Other',
						label: 'Other',
					}
				],
				Where_title: 'How did you know Simplus？',
				Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok',
					'Elevator advertisement'
				],
				Where_other: 'other (Please specify)',
				lastmeber: 'Hello, please fill out this form to get exclusive coupons! Thanks for submitting your message! Your special coupon will be sent to your email shortly.',
				lasttext: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
				downbtntext: 'Next step',
				upbtntexts: 'Previous page',
				upbtntext: 'Previous',
				submitext: 'Complete application',
			}
		}
	},
	{
		Nation: 'PH',
		newLangInfo: {
			//注册前弹窗
			registerModel: {
				title: 'Have you registered as Simplus member before?',
				button1: 'No, register now',
				button2: "Yes, log in",
				button3: 'Log in',
				tips: 'Please tick'
			},
			//注册页
			registerPage: {
				name: 'Name',
				email: 'Email',
				phone: 'Phone Number',
				gender: 'Gender',
				birth: 'Birthday',
				fill: 'Please fill in',
				select: 'Please select',
				last: 'Back',
				next: 'Next',
				submit: 'Submit',
				signup: 'Sign up',
				mandatory: 'Mandatory',
			},
			//注册页2
			questPage: {
				title: 'Where did you purchase Simplus products?',
				orderid: 'Order ID',
				upload: 'Upload receipt',
				offline: 'Offline channels',
				facebook: 'Your Facebook page link',
				other: 'Other',
			},
			//登录弹窗
			loginModel: {
				email: 'Email',
				phone: 'Phone Number',
				submit: 'Log in',
				tips: "Account doesn't exist or wrong entered",
				emailTips: 'Email format error',
				phoneTips: 'Phone number format error',
				phoneTips2:'You can sign in by email if you fail to sign in by mobile phone number'
			},
			//个人中心页
			infoPage: {
				pendding: 'Pending on Review',
				pendTitle: 'The result will be sent via your email. You can also sign in to view the process. Thanks for your patience!',
				fail: 'Registration Failed',
				failTitle: "We're sorry that your registration has failed, please contact our customer service team for help.",
				userId: 'ID',
				regDate: 'Registered Date',
				shareText: 'Share your review and get member exclusive voucher!',
				$20: '฿20 Voucher',
				$50: '50% off Voucher',
				unit: 'No Limit',
				collect: 'Collect',
				only: 'Only for Simplus Member',
				activity: 'Member Exclusive Activity'
			},
			//引导页
			guidePage: {
				register: 'Register now',
				signin: 'Log in',
				title: 'Simplus membership registration is for customers who have purchased Simplus products!',
				submit: "Shop Now",
			},
			//表单页
			formPage: {
				title20: 'Thank you for purchasing Simplus best-selling product, Air Fryer! Please fill in your order information to get your 20% off voucher, up to 500 off! Simplus looks forward to your next shopping!',
				title50: 'Thank you for purchasing Simplus best-selling product, Air Fryer! Please fill in your order information to get your 50% off voucher, up to 800PHP off! Simplus looks forward to your next shopping!',
				succTitle: 'Submission succeeds!',
				succText20: 'Our customer services team will verify your information within 3 working days. Thanks for your patience! Once verified. the 20% off voucher will be sent to you via Facebook chat box, please kindly check it!',
				succText50: 'Our customer services team will verify your information within 3 working days. Thanks for your patience! Once verified, the 50% off voucher will be sent to you via Facebook Messenger, please kindly check it!'
			},
			tipsInfo: {
				email: 'Email format error',
				birth: 'Invalid date of birth. Please re-enter.',
				gender: 'Input Error',
				exist: 'User has registered',
				order_error: 'Invalid order, please re-enter',
				phone: 'Phone number format error',
				order_used: 'This order has been used for the activity',
				http: 'Please enter the correct page link (starting with http).',
			}
		},
		index: {
			rtext: 'New Registration',
			rbtn: 'Join Now',
			stext: 'Already as Member',
			sbtn: 'Sgin in',
			ctext: 'Country',
		},
		user: {
			text: 'Query member information',
			ptitle: 'Phone Number',
			ptext: 'Phone No.',
			or: 'or',
			etitle: 'E-mail Address',
			etext: 'Email',
			ctext: 'Country',
			sbtn: 'Sgin in',
			rdtext: 'Registration date'
		},
		registration: {
			ctext: 'Country',
			PTip: 'Please enter mobile number without country code',
			tiptext: 'Thank you for choosing Simplus!  Join Simplus membership to get 1 year replacement warranty and exclusive voucher!  Fill in the form to complete the registration!',
			Privacytip: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
			tipredtext: 'Mandatory Field.',
			mobiletip: '* Please enter mobile number without country code.',
			Emailautotip: "Your coupon will be sent to your email shortly.",
			Nextstep: 'Next step',
			Previous: 'Previous',
			userCenter: 'user Center',
			prerequisites: {
				title: 'Have you shopped with Simplus before?',
				list: [{
					value: 'yes',
					label: 'Yes, I want to share my reviews and get 50%off voucher for my next order',
				}, {
					value: 'no',
					label: 'No, I want to become a Simplus member.'
				}],
			},
			member: {
				Title: 'POST REVIEW TO GET 10% OFF VOUCHER',
				onetitle: "Share your screenshot of the 5 stars review~ (Please don't put thank you card together within this photo)",
				onetip: "upload files",
				twotitle: 'Platform that you purchased from ',
				twolist: ['Lazada', 'Shopee', 'TikTok', 'Others'],
				Introductory: "Thank you for shopping with Simplus , please fill in your product review information to receive 50% off voucher on your next Simplus order",
				chatID: "Your Facebook account",
				chatTips: "Please fill in",
				OrderID: "Order ID",
				OrderTips: "Please fill in",
				Platform: " Your order platform",
				EvaluationPlatform: "Social platform where you leave reviews ",
				EvaluationURl: " Screenshot of your review page(optional)",
				EvaluationURlTips: "Please upload",
				EvaluationString: "Link to your review page",
				EvaluationStringTips: "Please fill in",
				Nation: null,
				btntitle: "Complete application",
				FormConclusion: "Congratulations! Your resgistration is successful! Come to shop and enjoy your 1 year warranty!",
			},
			sliginform: {
				Title: 'Sign up our Simplus Membership',
				e_title: 'E-mail Address',
				p_title: 'Phone Number',
				p_title_red: ' *(mobile number)',
				p_title_tip: 'Error entering mobile number',
				e_title_tip: 'Email format error',
				n_title: 'Name',
				d_title: 'Date of Birth',
				g_title: 'Gender',
				g_list: [{
						value: 'Male',
						label: 'Male',
					},
					{
						value: 'Female',
						label: 'Female',
					},
					{
						value: 'Not_Specified',
						label: 'Prefer not to say',
					},
					{
						value: 'Other',
						label: 'Other',
					}
				],
				Where_title: 'How did you know Simplus？',
				Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok',
					'Elevator advertisement'
				],
				Where_other: 'other (Please specify)',
				lastmeber: 'Hello, please fill out this form to get exclusive coupons! Thanks for submitting your message! Your special coupon will be sent to your email shortly.',
				lasttext: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
				downbtntext: 'Next step',
				upbtntexts: 'Previous page',
				upbtntext: 'Previous',
				submitext: 'Complete application',
			}
		}
	},
	{
		Nation: 'MY',
		newLangInfo: {
			//注册前弹窗
			registerModel: {
				title: 'Adakan anda berdaftar sebagai ahli Simplus sebelum ini?',
				button1: 'Tidak, mendaftar sekarang',
				button2: "Ya, log masuk",
				button3: 'Log in',
				tips: 'Please tick'
			},
			//注册页
			registerPage: {
				name: 'Name',
				email: 'Email',
				phone: 'Phone Number',
				gender: 'Gender',
				birth: 'Birthday',
				fill: 'Please fill in',
				select: 'Please select',
				last: 'Back',
				next: 'Next',
				submit: 'Submit',
				signup: 'Sign up',
				mandatory: 'Mandatory',
			},
			//注册页2
			questPage: {
				title: 'Where did you purchase Simplus products?',
				orderid: 'Order ID',
				upload: 'Upload receipt',
				offline: 'Offline channels',
				facebook: 'Your Facebook page link',
				other: 'Other',
			},
			//登录弹窗
			loginModel: {
				email: 'Email',
				phone: 'Phone Number',
				submit: 'Log in',
				tips: "Account doesn't exist or wrong entered",
				emailTips: 'Email format error',
				phoneTips: 'Phone number format error',
				phoneTips2:"You can sign in by email if you fail to sign in by mobile phone number"
			},
			//个人中心页
			infoPage: {
				pendding: 'Pending on Review',
				pendTitle: 'The result will be sent via your email. You can also sign in to view the process. Thanks for your patience!',
				fail: 'Registration Failed',
				failTitle: "We're sorry that your registration has failed, please contact our customer service team for help.",
				userId: 'ID',
				regDate: 'Registered Date',
				shareText: 'Share your review and get member exclusive voucher!',
				$20: '฿20 Voucher',
				$50: '50% off Voucher',
				unit: 'No Limit',
				collect: 'Collect',
				only: 'Only for Simplus Member',
				activity: 'Member Exclusive Activity'
			},
			//引导页
			guidePage: {
				register: 'Register now',
				signin: 'Log in',
				title: 'Simplus membership registration is for customers who have purchased Simplus products!',
				submit: "Shop Now",
			},
			//表单页
			formPage: {
				title20: 'Thank you for purchasing Simplus best-selling product, Air Fryer! Please fill in your order information to get your 20% off voucher, up to 500 off! Simplus looks forward to your next shopping!',
				title50: 'Thank you for purchasing Simplus best-selling product, Air Fryer! Please fill in your order information to get your 50% off voucher, up to RM60 off! Simplus looks forward to your next shopping!',
				succTitle: 'Submission succeeds!',
				succText20: 'Our customer services team will verify your information within 3 working days. Thanks for your patience! Once verified. the 20% off voucher will be sent to you via Facebook chat box, please kindly check it!',
				succText50: 'Our customer services team will verify your information within 3 working days. Thanks for your patience! Once verified, the 50% off voucher will be sent to you via Facebook Messenger, please kindly check it!'
			},
			tipsInfo: {
				email: 'Email format error',
				birth: 'Invalid date of birth. Please re-enter.',
				gender: 'Input Error',
				exist: 'User has registered',
				order_error: 'Invalid order, please re-enter',
				phone: 'Phone number format error',
				order_used: 'This order has been used for the activity',
				http: 'Please enter the correct page link (starting with http).',
			}
		},
		index: {
			rtext: 'New Registration',
			rbtn: 'Join Now',
			stext: 'Already as Member',
			sbtn: 'Sgin in',
			ctext: 'Country',
		},
		user: {
			text: 'Query member information',
			ptitle: 'Phone Number',
			ptext: 'Phone No.',
			or: 'or',
			etitle: 'E-mail Address',
			etext: 'Email',
			ctext: 'Country',
			sbtn: 'Sgin in',
			rdtext: 'Registration date'
		},
		registration: {
			ctext: 'Country',
			PTip: 'Please enter mobile number without country code',
			tiptext: 'Thank you for choosing Simplus!  Join Simplus membership to get 1 year replacement warranty and exclusive voucher!  Fill in the form to complete the registration!',
			Privacytip: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
			tipredtext: 'Mandatory Field.',
			mobiletip: '* Please enter mobile number without country code.',
			Emailautotip: "Your coupon will be sent to your email shortly.",
			Nextstep: 'Next step',
			Previous: 'Previous',
			userCenter: 'user Center',
			prerequisites: {
				title: 'Have you shopped with Simplus before?',
				list: [{
					value: 'yes',
					label: 'Yes, I want to share my reviews and get 50%off voucher for my next order',
				}, {
					value: 'no',
					label: 'No, I want to become a Simplus member.'
				}],
			},
			member: {
				Title: 'POST REVIEW TO GET 10% OFF VOUCHER',
				onetitle: "Share your screenshot of the 5 stars review~ (Please don't put thank you card together within this photo)",
				onetip: "upload files",
				twotitle: 'Platform that you purchased from ',
				twolist: ['Lazada', 'Shopee', 'TikTok', 'Others'],
				Introductory: "Thank you for shopping with Simplus , please fill in your product review information to receive 50% off voucher on your next Simplus order",
				chatID: "Your Facebook account",
				chatTips: "Please fill in",
				OrderID: "Order ID",
				OrderTips: "Please fill in",
				Platform: " Your order platform",
				EvaluationPlatform: "Social platform where you leave reviews ",
				EvaluationURl: " Screenshot of your review page(optional)",
				EvaluationURlTips: "Please upload",
				EvaluationString: "Link to your review page",
				EvaluationStringTips: "Please fill in",
				Nation: null,
				btntitle: "Complete application",
				FormConclusion: "Congratulations! Your resgistration is successful! Come to shop and enjoy your 1 year warranty!",
			},
			sliginform: {
				Title: 'Sign up our Simplus Membership',
				e_title: 'Email Address',
				p_title: 'Phone Number',
				p_title_red: ' *(mobile number)',
				p_title_tip: 'Error entering mobile number',
				e_title_tip: 'Email format error',
				n_title: 'Name',
				d_title: 'Date of Birth',
				g_title: 'Gender',
				g_list: [{
						value: 'Male',
						label: 'Male',
					},
					{
						value: 'Female',
						label: 'Female',
					},
					{
						value: 'Not_Specified',
						label: 'Prefer not to say',
					},
					{
						value: 'Other',
						label: 'Other',
					}
				],
				Where_title: 'How did you know Simplus？',
				Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok',
					'Elevator advertisement',
				],
				Where_other: 'other (Please specify)',
				lastmeber: 'Hello, please fill out this form to get exclusive coupons! Thanks for submitting your message! Your special coupon will be sent to your email shortly.',
				lasttext: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
				downbtntext: 'Next step',
				upbtntexts: 'Previous page',
				upbtntext: 'Previous',
				submitext: 'Complete application',
			}
		}
	}
]
